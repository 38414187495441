import React from 'react'
import { Analytics } from '@genoa/analytics'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { PROPERTY_PASSTHROUGH_FEE } from '@genoa/screen-content'
import { toFormattedDollars } from '@genoa/utils'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useModal } from '../../../contexts'
import { useAnalytics, useFees } from '../../../providers'
import { ExtraSmallText, Gap, LinkText, SmallText } from '../../../views/components'

export const usePropertyPassthroughFee = (fee?: number) => {
  const modal = useModal()
  const analytics = useAnalytics()
  const theme = useTheme()
  const { propertyPassthroughFee: pricingPropertyPassthroughFee } = useFees()
  const propertyPassthroughFee = fee === undefined ? pricingPropertyPassthroughFee : fee
  const displayPropertyPassthroughFee = !!propertyPassthroughFee && propertyPassthroughFee > 0

  const displayPropertyPassthroughFeeModal = () => {
    analytics.logEvent(Analytics.Events.PROPERTY_PASSTHROUGH_FEE_TOOLTIP_CLICKED)
    modal.show({
      title: PROPERTY_PASSTHROUGH_FEE.MODAL.HEADER,
      cta: PROPERTY_PASSTHROUGH_FEE.MODAL.CTA_TEXT,
      onClose: () => analytics.logEvent(Analytics.Events.PROPERTY_PASSTHROUGH_FEE_MODAL_DONE_CLICKED),
      render: () => (
        <Box>
          <SmallText>{PROPERTY_PASSTHROUGH_FEE.MODAL.PARAGRAPH}</SmallText>
          <Gap size="spacing_100" />
        </Box>
      ),
    })
  }

  const PropertyPassthroughFeeDisclaimer = () => {
    return displayPropertyPassthroughFee ? (
      <Box alignItems="flex-end" mt="spacing_12_5">
        <RowContainer>
          <ExtraSmallText color={theme.colors.dusk}>
            {PROPERTY_PASSTHROUGH_FEE.PAYMENT_SCHEDULE_DISCLAIMER(toFormattedDollars(propertyPassthroughFee))}{' '}
          </ExtraSmallText>
          <LinkText fontSize={theme.fontSizes.xxs} onClick={displayPropertyPassthroughFeeModal}>
            <b>{PROPERTY_PASSTHROUGH_FEE.PAYMENT_SCHEDULE_LINK_BUTTON}</b>
          </LinkText>
        </RowContainer>
      </Box>
    ) : null
  }

  const propertyPassthroughFeeLineItem = {
    label: PROPERTY_PASSTHROUGH_FEE.PAYMENT_DETAILS_LABEL,
    amount: toFormattedDollars(propertyPassthroughFee),
    moreInfo: displayPropertyPassthroughFeeModal,
  }

  return {
    displayPropertyPassthroughFeeModal,
    displayPropertyPassthroughFee,
    PropertyPassthroughFeeDisclaimer,
    propertyPassthroughFeeLineItem,
  }
}

export const RowContainer = styled(Box)`
  flex-direction: row;
`
