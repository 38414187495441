import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EMBED_SETTINGS as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../hooks'
import { useEmbedBlockingScreens } from '../../../../../hooks/embed'
import { useAnalytics } from '../../../../../providers'
import { ChevronLeftPurple } from '../../../../assets'
import { Gap, Headline1, InlineButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { SettingsAutopayToggle } from './SettingsAutopayToggle'
import { SettingsChangeCard } from './SettingsChangeCard'

export const Settings = () => {
  const { embedFlow, embedProperties } = useEmbedAnalytics()
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const [isLoadingToggleAutopay, setIsLoadingToggleAutopay] = useState(false)
  const [hasAutopayUpdated, setHasAutopayUpdated] = useState(false)
  const { checkBlockingStates, runEmbedPayNowEligibilityQuery } = useEmbedBlockingScreens({
    defaultNavigateToCongrats: true,
  })

  const onBack = async () => {
    analytics.logEvent(Analytics.Events.EMBED_SETTINGS_BACK_CLICKED, { embedFlow })
    if (hasAutopayUpdated) {
      checkBlockingStates()
    } else {
      navigate(-1)
    }
  }

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_SETTINGS} analyticsScreenParams={embedProperties}>
      <Container>
        <BackButtonContainer>
          <BackButton disabled={isLoadingToggleAutopay} onClick={onBack}>
            <Flex alignItems="center">
              <LeftChevron />
              <BackText>{content.BACK}</BackText>
            </Flex>
          </BackButton>
        </BackButtonContainer>
        <Gap size="spacing_200" />
        <Headline1>{content.HEADER}</Headline1>

        <ActionsContainer>
          <SettingsAutopayToggle
            setHasAutopayUpdated={setHasAutopayUpdated}
            runEmbedPayNowEligibilityQuery={runEmbedPayNowEligibilityQuery}
            setIsLoadingToggleAutopay={setIsLoadingToggleAutopay}
          />
          <SettingsChangeCard isLoadingToggleAutopay={isLoadingToggleAutopay} />
        </ActionsContainer>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
`

const BackButtonContainer = styled(Flex)`
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_200};
`

const BackButton = styled(InlineButton)`
  color: ${({ theme }) => theme.colors.jewelPurple};
`

const LeftChevron = styled(ChevronLeftPurple)`
  height: ${({ theme }) => theme.fixedSizes.md};
`

const BackText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.jewelPurple};
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const ActionsContainer = styled(Flex)`
  flex-direction: column;
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_200};
`
