import { useMemo, useState } from 'react'
import {
  Agreement,
  Installment,
  LineItemType,
  PaymentCategoryV2,
  PaymentMethodType,
  PaymentStatusFull,
} from '@genoa/domain'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { deepReplaceContent, EMBED_RELINK_ACCEPT_OFFER_DETAILS, getAcceptanceOfferContent } from '@genoa/screen-content'
import { getDateTime, getDateTimeFromISO, getResumeDate } from '@genoa/utils'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useAuthState } from '../../../../contexts'
import {
  useAutopilotEligibility,
  useContentOverhaul,
  useFlexAnywhereUser,
  useIsEmbed,
  useOnboardingAutopayToggleExperiment,
} from '../../../../hooks'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useAmplitudeFeatureFlag } from '../../../../hooks/use-amplitude-feature-flag'
import { useCustomerWalletState } from '../../../../modules/flex2/use-state'
import { useGetPaymentStatusQuery } from '../../../../modules/flexApi'
import { AutopayStatusButton, AutopayToggle, Gap, Headline1, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { AcceptanceOfferAuthorize } from './AcceptanceOfferAuthorize'
import { PaymentSchedule } from './payment-schedule'
import { paymentStatusPaymentsToPaymentScheduleItems } from './payment-schedule/paymentStatusPaymentsToPaymentScheduleItems'

type AcceptanceOfferDetailsProps = {
  onLearnMore: () => unknown
  onMembershipLearnMore: () => unknown
  onChooseDate: () => unknown
  onChangeAutopay: () => unknown
  onAcceptTerms: () => unknown
  onNext: () => unknown
  loading: boolean
  termsAccepted: boolean
  rentAmount: number
  offerFee: number
  payments: Installment[]
  ctaTitle: string
  showExtraFees?: boolean
  processingFeePercentage?: number
  membershipFeeReason?: string
  agreements: Agreement[]
  isRelink?: boolean
  showSettings?: boolean
}

export const AcceptanceOfferDetails = (props: AcceptanceOfferDetailsProps) => {
  const customerWalletState = useCustomerWalletState()
  const { isSimplifiedPaymentsEnabled, isEnabledForSLC, isPricingEnabled } = useSecureLineOfCredit()
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const theme = useTheme()
  const isEmbed = useIsEmbed()
  const [isLoadingToggleAutopay, setIsLoadingToggleAutopay] = useState(false)
  const { isUserAutopayEnabled } = useAutopilotEligibility()
  const { isOnboardingAutopayToggleEnabled } = useOnboardingAutopayToggleExperiment()
  const { isConfirmScheduleEnabled } = useContentOverhaul()

  const content = useMemo(() => {
    const acceptanceOfferContent = getAcceptanceOfferContent({
      isOutOfNetworkUser,
      isEnabledForSLC,
      isEmbed,
      isAutopayToggleEnabled: isOnboardingAutopayToggleEnabled && !isOutOfNetworkUser,
      isContentOverhaulEnabled: isConfirmScheduleEnabled,
    })

    const today = getDateTime()
    const resumeDate = getResumeDate(today)

    return deepReplaceContent(acceptanceOfferContent, {
      date: resumeDate.monthLong || 'next',
      fee: String(props.processingFeePercentage || 0),
    })
  }, [
    isOutOfNetworkUser,
    isEnabledForSLC,
    isEmbed,
    props.processingFeePercentage,
    isOnboardingAutopayToggleEnabled,
    isConfirmScheduleEnabled,
  ])

  const disclaimer =
    customerWalletState?.defaultCard?.card_type === PaymentMethodType.CREDIT
      ? content.FEE_DISCLAIMER_CREDIT
      : content.FEE_DISCLAIMER

  const showAutopayStatusButton = isOnboardingAutopayToggleEnabled && !props.isRelink && !isOutOfNetworkUser

  return (
    <BasePageLayout showSettings={props.showSettings}>
      <Box height={theme.fixedSizes.spacing_200} />

      <Headline1 data-testid="AcceptanceOfferDetailsHeader">
        {props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.HEADER : content.HEADER}
      </Headline1>

      <Box minH={theme.fixedSizes.spacing_150} />

      <SmallText data-testid="AcceptanceOfferDetailsBody">
        {content.BODY_1}
        {content.BODY_BOLD && <b>{content.BODY_BOLD}</b>}
        {content.BODY_2}
      </SmallText>

      <Box minH={theme.fixedSizes.spacing_250} />

      {showAutopayStatusButton && (
        <Box marginBottom={theme.fixedSizes.spacing_100}>
          <AutopayStatusButton status={isUserAutopayEnabled} onChange={props.onChangeAutopay} />
        </Box>
      )}

      <PaymentSchedule
        payments={props.payments}
        fee={props.offerFee}
        onLearnMore={props.onLearnMore}
        onMembershipLearnMore={props.onMembershipLearnMore}
        onRepaymentDayChange={props.onChooseDate}
        showExtraFees={props.showExtraFees}
        showProcessingFeePercentage={!!props.processingFeePercentage}
        membershipFeeReason={props.membershipFeeReason}
        feeDisclaimerLabel={disclaimer}
        initialPaymentLabel={content.INITIAL_PAYMENT_LABEL}
        hideChangeDate
        isSimplifiedPaymentsEnabled={isSimplifiedPaymentsEnabled}
        isEnabledForSLC={isEnabledForSLC}
        showInterestFee={isPricingEnabled}
        splitMembershipFee={isOnboardingAutopayToggleEnabled && !isUserAutopayEnabled}
      />

      <Box height="32px" />

      {isEmbed && !showAutopayStatusButton && (
        <>
          <AutopayToggle setIsLoadingToggleAutopay={setIsLoadingToggleAutopay} />
          <Box height={theme.fixedSizes.spacing_150} />
        </>
      )}

      <AcceptanceOfferAuthorize
        agreeText={props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.AUTHORIZATION : content.AGREE}
        authorizationText={!props.isRelink ? content.AUTHORIZATION : undefined}
        toggleAcceptTerms={props.onAcceptTerms}
        termsAccepted={props.termsAccepted}
        fetchedAgreements={props.agreements}
      />

      <Box height={theme.fixedSizes.spacing_150} />

      <PrimaryButton
        testID="AcceptanceOfferDetailsNext"
        onClick={props.onNext}
        disabled={!props.termsAccepted || props.loading || isLoadingToggleAutopay}
        processing={props.loading}
      >
        {props.ctaTitle}
      </PrimaryButton>

      <Box minH={theme.fixedSizes.spacing_100} />
    </BasePageLayout>
  )
}
