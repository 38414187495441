import { CONTROL, MayBpSoftCreditCheckExperiment } from '@genoa/experiments'

import { useExperiment } from './use-experiment'

interface MayBpSoftCreditCheck {
  mainText: string
  subText: string
  isVariant: boolean
}

const CONTROL_MAIN_TEXT = ''
const CONTROL_SUB_TEXT = ''

export const useMayBpSoftCreditCheck = (): MayBpSoftCreditCheck => {
  const { variant } = useExperiment(MayBpSoftCreditCheckExperiment)
  if (variant.payload && variant.value) {
    return {
      mainText: variant.payload.mainText,
      subText: variant.payload.subText,
      isVariant: variant.value !== CONTROL,
    }
  }
  return {
    mainText: CONTROL_MAIN_TEXT,
    subText: CONTROL_SUB_TEXT,
    isVariant: false,
  }
}
