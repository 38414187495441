import React from 'react'
import { PORTAL_ADDRESS } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { InlineButton, PrimaryButton, Text } from '../../../../components'
import { Modal } from '../../../../components/Modal'
import { PortalAddress } from './types'

export interface LocationSuggestModalProps {
  readonly visible: boolean
  readonly onClose: () => unknown
  readonly onNext: () => unknown
  readonly portalAddress?: PortalAddress
  readonly suggestedAddress?: PortalAddress
  handleUseOriginalAddress: () => unknown
}

export const LocationSuggestModal = (props: LocationSuggestModalProps) => {
  const theme = useTheme()

  return props.portalAddress && props.suggestedAddress ? (
    <Modal visible={props.visible} title="Confirm your address" canClose onClose={props.onClose}>
      <Box textAlign="left">
        <Text data-testid="LocationSuggestBody">{PORTAL_ADDRESS.BODY}</Text>
        <Box minH={6} />
        <Text color={theme.colors.dusk}>{PORTAL_ADDRESS.ORIGINAL_ADDRESS}</Text>
        <Box minH={1} />

        <Box borderWidth={1} borderColor={theme.colors.cloud} />
        <Box minH={1} />

        <Text fontWeight="bold">{`${props.portalAddress.address_line_1} `}</Text>
        <br />
        {props.portalAddress.address_line_2 && (
          <>
            <Text fontWeight="bold">{`${props.portalAddress.address_line_2}`}</Text>
            <br />
          </>
        )}

        <Text fontWeight="bold">{`${props.portalAddress.city}, ${props.portalAddress.state} ${props.portalAddress.zip}`}</Text>
        <Box minH={6} />
        <Text color={theme.colors.dusk}>{PORTAL_ADDRESS.SUGGESTED_ADDRESS}</Text>
        <Box minH={1} />

        <Box borderWidth={1} borderColor={theme.colors.cloud} />
        <Box minH={1} />

        <Text fontWeight="bold">{props.suggestedAddress.address_line_1}</Text>
        <br />

        {props.suggestedAddress.address_line_2 && (
          <>
            <Text fontWeight="bold">{props.suggestedAddress.address_line_2}</Text>
            <br />
          </>
        )}
        <Text fontWeight="bold">{`${props.suggestedAddress.city}, ${props.suggestedAddress.state} ${props.suggestedAddress.zip}`}</Text>
      </Box>
      <Box minH={4} />

      <InlineButton {...props} testID="useOriginalAddress" onClick={props.handleUseOriginalAddress}>
        <Text color={theme.colors.jewelPurple}>{PORTAL_ADDRESS.ORIGINAL_CTA}</Text>
      </InlineButton>
      <Box minH={4} />

      <PrimaryButton testID="UseSuggestedAddressButton" onClick={props.onNext}>
        {PORTAL_ADDRESS.SUGGESTED_CTA}
      </PrimaryButton>
    </Modal>
  ) : null
}
