import { MayBpSkipSmsOptInExperiment } from '@genoa/experiments'

import { useExperiment } from './use-experiment'

interface MayBpSkipSmsOptIn {
  buttonText: string
  showSkipButton: boolean
}

const CONTROL_BUTTON_TEXT = ''
const CONTROL_SHOW_SKIP_BUTTON = false

export const useMayBpSkipSmsOptIn = (): MayBpSkipSmsOptIn => {
  const { variantPayload } = useExperiment(MayBpSkipSmsOptInExperiment)
  if (variantPayload) {
    return variantPayload
  }
  return {
    buttonText: CONTROL_BUTTON_TEXT,
    showSkipButton: CONTROL_SHOW_SKIP_BUTTON,
  }
}
