import { Control, FormState, UseFormReturn } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { CO_SOFT_CREDIT_CHECK as content } from '@genoa/screen-content'
import { Box, Flex, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useMayBpSoftCreditCheck } from '../../../../hooks/experiments/use-may-bp-soft-credit-check'
import { QuestionCircle } from '../../../assets'
import { ExtraSmallText, Field, Headline1, PrimaryButton, SmallText, TextInput } from '../../../components'
import { BasePageLayout } from '../../../layouts'

export type SoftCreditCheckState = {
  dob: string
  ssn: string
  income: string
}

type SoftCreditCheckProps = {
  analyticsScreenName: Analytics.Screens
  onDateChange: (date: string) => void
  onSetSSN: (ssn: string) => void
  onSetIncome: (income: string) => void
  onClickContinue: () => void
  ssnPlaceholder: string
  isLoading: boolean
  onLearnMore: () => void
  onPressWhy: () => void
  control: Control<SoftCreditCheckState>
  formErrors: FormState<SoftCreditCheckState>['errors']
  getValues: UseFormReturn<SoftCreditCheckState>['getValues']
}

export const SoftCreditCheck = (props: SoftCreditCheckProps) => {
  const theme = useTheme()
  const ssn = props.getValues('ssn')
  const date = props.getValues('dob')
  const baseDisabled = !ssn || !date || props.isLoading
  const { mainText, subText, isVariant } = useMayBpSoftCreditCheck()

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Flex direction="column">
        <Box minH="30px" />

        <Headline1>{content.HEADER}</Headline1>

        <Box minH="24px" />
        <Box flex={1}>
          <SmallText data-testid="SoftCreditCheckBody">
            {content.BODY_1} {content.BODY_CREDIT_REPORT_LINK_TEXT} {content.BODY_2}
            {isVariant && (
              <>
                {' ' + mainText}
                <LearnMoreInline testID="SoftCreditCheckLearnMore" onClick={props.onLearnMore}>
                  <LearnMoreButtonContent>
                    <QuestionCircle />
                  </LearnMoreButtonContent>
                </LearnMoreInline>
              </>
            )}
          </SmallText>
        </Box>
        {isVariant ? (
          <SmallText marginTop={subText ? theme.fixedSizes.spacing_150 : 0}>{subText}</SmallText>
        ) : (
          <Box marginTop={theme.fixedSizes.spacing_150} flex={1}>
            <Row>
              <SmallText fontWeight="bold">{content.LEARN_MORE}</SmallText>
              <LearnMoreButton testID="SoftCreditCheckLearnMore" onClick={props.onLearnMore}>
                <LearnMoreButtonContent>
                  <QuestionCircle />
                </LearnMoreButtonContent>
              </LearnMoreButton>
            </Row>
          </Box>
        )}
        <Box minH={theme.fixedSizes.lg} />

        <Box>
          <Field
            control={props.control}
            defaultValue={date}
            error={props.formErrors.dob}
            input={TextInput}
            label="Date of birth"
            name="dob"
            onChange={props.onDateChange}
            placeholder="MM / DD / YYYY"
            preventChangeValue
            testID="SoftCreditCheckDateOfBirthInput"
          />
        </Box>

        <Box minH={theme.fixedSizes.md} />

        <Box>
          <Field
            control={props.control}
            error={props.formErrors.ssn}
            input={TextInput}
            label="Social security number"
            name="ssn"
            onChange={props.onSetSSN}
            placeholder={props.ssnPlaceholder}
            preventChangeValue
            testID="SoftCreditCheckSsnInput"
          />
        </Box>
        <Box minH={theme.fixedSizes.md} />

        <Box minH={theme.fixedSizes.md} />
        {isVariant ? (
          <ExtraSmallText>
            {content.FEDERAL_LAW_DISCLAIMER}
            <WhyText color={theme.colors.jewelPurple} onClick={props.onPressWhy}>
              {content.LINK_TEXT}
            </WhyText>
          </ExtraSmallText>
        ) : (
          <FederalLawDisclaimerWrapper>
            <ExtraSmallText>
              {content.FEDERAL_LAW_DISCLAIMER}
              <WhyText color={theme.colors.jewelPurple} onClick={props.onPressWhy}>
                {content.LINK_TEXT}
              </WhyText>
            </ExtraSmallText>
          </FederalLawDisclaimerWrapper>
        )}
        <>
          <Box minH="64px" />
          <ExtraSmallText data-testid="SoftCreditCheckAuth">{content.AUTHORIZATION}</ExtraSmallText>
          <Box minH="16px" />
        </>
        <PrimaryButton
          disabled={baseDisabled}
          processing={props.isLoading}
          onClick={props.onClickContinue}
          testID="SoftCreditCheckContinueButton"
        >
          {content.CTA}
        </PrimaryButton>

        <Box minH="16px" />
      </Flex>
    </BasePageLayout>
  )
}

const LearnMoreButton = styled(Box)`
  display: inline-flex;
  cursor: pointer;
  padding-left: ${(props) => props.theme.fixedSizes.spacing_37_5};
`
const LearnMoreInline = styled(Box)`
  display: inline-flex;
  position: absolute;
  cursor: pointer;
  padding-left: ${(props) => props.theme.fixedSizes.spacing_37_5};
  padding-top: ${(props) => props.theme.fixedSizes.spacing_12_5};
`
const LearnMoreButtonContent = styled(SmallText)`
  color: ${(props) => props.theme.colors.brand['900']};
`
const FederalLawDisclaimerWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  padding: ${(props) => props.theme.fixedSizes.spacing_50};
  justify-content: center;
  align-items: center;
`

const WhyText = styled(ExtraSmallText)`
  font-weight: bold;
  cursor: pointer;
`

const LabelQuestionArea = styled(Box)`
  margin-left: ${(props) => props.theme.fixedSizes.spacing_25};
`
const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
