import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { capitalizeFirstLetter } from '@genoa/utils'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../hooks'
import { useGetCards } from '../../../../../hooks'
import { useCustomerWalletState } from '../../../../../modules/flex2/use-state'
import { useAnalytics } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { CardIcon } from '../../../../assets'
import { SmallText } from '../../../../components'

interface SettingsChangeCardProps {
  isLoadingToggleAutopay: boolean
}

export const SettingsChangeCard = (props: SettingsChangeCardProps) => {
  const { embedFlow } = useEmbedAnalytics()
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const { defaultCard, initialized } = useCustomerWalletState()
  const cardType = defaultCard?.card_type
  const cardMask = defaultCard?.card_mask
  const cardBrand = defaultCard?.brand
  const { getDefaultCard } = useGetCards({ componentName: 'SettingsChangeCard' })

  useEffect(() => {
    if (!initialized) {
      getDefaultCard()
    }
  }, [initialized])

  const onChangeCard = () => {
    analytics.logEvent(Analytics.Events.EMBED_SETTINGS_CHANGE_CARD, { embedFlow })
    navigate(Routes.Embed.CHANGE_CARD)
  }

  if (!cardType || !cardMask || !cardBrand || props.isLoadingToggleAutopay) {
    return null
  }

  return (
    <Frame>
      <CardIcon />
      <CardInfoContainer>
        <SmallText data-testid="ChangeCardType">{`${capitalizeFirstLetter(cardType)} card`}</SmallText>
        <CardInfo data-testid="ChangeCardInfo">{`${capitalizeFirstLetter(cardBrand)} x${cardMask}`}</CardInfo>
      </CardInfoContainer>
      <ChangeCardLink data-testid="ChangeCardLink" onClick={onChangeCard}>
        Change Card
      </ChangeCardLink>
    </Frame>
  )
}

const Frame = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.wildflower};
  border: 1px solid ${({ theme }) => theme.colors.cloud};
  border-radius: ${({ theme }) => theme.radii.xl};
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_200};
  padding: ${({ theme }) => theme.fixedSizes.spacing_125} ${({ theme }) => theme.fixedSizes.spacing_150};
`

const CardInfoContainer = styled(Flex)`
  flex-direction: column;
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const CardInfo = styled(SmallText)`
  color: ${({ theme }) => theme.colors.dusk};
`

const ChangeCardLink = styled(SmallText)`
  color: ${({ theme }) => theme.colors.jewelPurple};
  cursor: pointer;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-left: auto;
`
