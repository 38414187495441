import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { DATE_LENGTH, FlexLinks, isDOBValid, isValidITIN, isValidSSN } from '@genoa/domain'
import {
  OfferStates,
  OrchestrationEvaluateOfferResponse,
  OrchestrationOfferResponse,
  useEvaluateOffer,
} from '@genoa/middle-end'
import { EMBED_RELINK_VERIFY_IDENTITY } from '@genoa/screen-content'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import * as yup from 'yup'

import { useAuthState, useModal } from '../../../../../../contexts'
import { useAutopilotEligibility, useGeometricInterval, useReduxAction } from '../../../../../../hooks'
import {
  useSecureLineOfCredit,
  useTrackExposureSecureLineOfCredit,
} from '../../../../../../hooks/secure-line-of-credit'
import { useAccount } from '../../../../../../hooks/use-account'
import { setOfferAction } from '../../../../../../modules/flex2/offer'
import { setPricingOfferAction } from '../../../../../../modules/flex2/pricing-offer'
import { useOfferState } from '../../../../../../modules/flex2/use-state'
import { useAnalytics, useEnhancedTracking, useHelpLinks, useIterable, useLogger } from '../../../../../../providers'
import {
  createUnderwritingApprovedEvent,
  createUnderwritingDeniedEvent,
} from '../../../../../../providers/iterable/user-events'
import * as Routes from '../../../../../../routing/constants'
import { SmallText } from '../../../../../components'
import { DateOfBirth, ssn as SSNMask } from '../../../../../components/Input'
import { VerifyIdentity, VerifyIdentityState } from './VerifyIdentity'
import { VerifyingIdentity } from './VerifyingIdentity'

const ssnPlaceholder = 'XXX - XX - XXXX'

const isValidResponseData = (data: any): data is OrchestrationOfferResponse => {
  return typeof data === 'object' && 'risk_offer' in data
}

const verifyIdentitySchema = yup.object({
  dob: yup.string().required('Date of Birth is required').trim(),
  ssn: yup.string().required('SSN is required').trim(),
})

export const VerifyIdentityContainer = () => {
  const [loadingEvaluation, setLoadingEvaluation] = useState(false)
  const { uid } = useAuthState()
  const { billerConnection } = useAccount()
  const logger = useLogger('ConfirmAccountContainer')
  const setOfferState = useReduxAction(setOfferAction)
  const setPricingOfferState = useReduxAction(setPricingOfferAction)
  const modal = useModal()
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const { trackUnderwritingRequested } = useEnhancedTracking()
  const iterable = useIterable()
  const helpLinks = useHelpLinks()
  const theme = useTheme()
  const { refetchEligibility, isUserEligibleForAutopilot } = useAutopilotEligibility()
  const { refetchSLC, isEnabledForSLC, isFlagEnabledForSLC, slcType } = useSecureLineOfCredit()
  const { trackSLCExposure } = useTrackExposureSecureLineOfCredit()

  const {
    clearErrors,
    control,
    formState: { errors: formErrors },
    getValues,
    handleSubmit,
    setError,
    setValue,
  } = useForm<VerifyIdentityState>({
    resolver: yupResolver(verifyIdentitySchema),
    mode: 'onChange',
  })

  const { start, stop } = useGeometricInterval(
    useCallback((interval, elapsed) => {
      analytics.logEvent(Analytics.Events.ONBOARDING_WAITING_OFFER, { interval, elapsed })
    }, []),
    15000
  )

  const redirectToContactSupport = () => {
    navigate(Routes.Onboarding.CONTACT_SUPPORT, { replace: true })
  }

  const offerState = useOfferState()

  const [{ response }, evaluateOffer] = useEvaluateOffer()

  const getErrorModalContent = (status: number, data: OrchestrationEvaluateOfferResponse) => {
    if (status === 422 && !isValidResponseData(data) && data?.message) {
      return {
        children: <SmallText>{data.message}</SmallText>,
        title: EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_TITLE1,
      }
    } else if (status === 400) {
      return {
        children: <SmallText>{EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_CONTENT1}</SmallText>,
        title: EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_TITLE2,
      }
    }
    return {
      children: (
        <SmallText>
          {EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_CONTENT2}
          <SmallText
            onClick={() => helpLinks.open(FlexLinks.helpHome)}
            color={theme.colors.brand[200]}
            fontWeight="bold"
          >
            {EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_CONTENT3}
          </SmallText>{' '}
          <SmallText>{EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_CONTENT4}</SmallText>
        </SmallText>
      ),
      title: EMBED_RELINK_VERIFY_IDENTITY.ERROR_MODAL_TITLE1,
    }
  }

  const handleShowErrorMessage = (status: number, data: OrchestrationEvaluateOfferResponse) => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_ERROR_MODAL)
    const errorReason = data && !isValidResponseData(data) ? data.reason : 'unknown'
    logger.error('Evaluate Offer Failed', `status: ${status} - isRelink: true - reason: ${errorReason}`)
    const errorModalContent = getErrorModalContent(status, data)
    modal.show({
      title: errorModalContent.title,
      cta: 'Confirm',
      render: () => errorModalContent.children,
    })
  }

  const redirectToOfferDetails = async () => {
    if (isFlagEnabledForSLC) {
      trackSLCExposure(isEnabledForSLC)
    }
    return navigate(Routes.Embed.RELINK_OFFER_DETAILS, { replace: true })
  }

  const handleOfferEvaluationResponse = async (
    response: AxiosResponse<OrchestrationEvaluateOfferResponse> | undefined
  ) => {
    if (response) {
      stop()
      if (response.status === 201 && isValidResponseData(response.data)) {
        const offer = response.data.risk_offer
        const pricingoffer = response.data.pricing_offer

        if (!offer || !pricingoffer) {
          logger.error('Evaluate Offer Failed', `missing data, risk_offer: ${!offer}, pricing_offer: ${!pricingoffer}`)
          redirectToContactSupport()
          return
        }

        setOfferState({ initialized: true, offer })
        setPricingOfferState({ pricing_offer: pricingoffer })

        analytics.logEvent(Analytics.Events.ONBOARDING_WAITING_OFFER_FOUND)
        switch (offer.offer_state) {
          case OfferStates.PENDING_ACCEPT:
            iterable.addEvent(createUnderwritingApprovedEvent())
            redirectToOfferDetails()
            break
          case OfferStates.DENIED:
            const dataFields = { denial_reason: 'Denied' }
            iterable.addEvent(createUnderwritingDeniedEvent({ dataFields }))
            navigate(Routes.Embed.SOMETHING_WENT_WRONG, { replace: true })
            break
          default:
            logger.error('Evaluate Offer Failed', `offer_state is not PendingAccept on offer: ${offer.offer_id}`)
            redirectToContactSupport()
            break
        }
      } else if (response.status >= 400 && response.status < 500) {
        handleShowErrorMessage(response.status, response.data)
      } else {
        redirectToContactSupport()
        logger.error('Evaluate Offer Failed', `Status: ${response.status}`)
      }
    }
  }

  useEffect(() => {
    if (response && !loadingEvaluation) {
      handleOfferEvaluationResponse(response)
    }
  }, [response, stop, loadingEvaluation, isUserEligibleForAutopilot])

  const onSetSSN = (ssn: string) => {
    const maskedSSN = SSNMask.mask(ssn)
    clearErrors('ssn')
    setValue('ssn', maskedSSN)
  }

  const onSetDate = (dateOfBirth: string) => {
    const maskedDate = DateOfBirth.mask(dateOfBirth)
    clearErrors('dob')
    setValue('dob', maskedDate)
  }

  const getDoB = () => {
    const date = getValues('dob')
    const asDate = DateOfBirth.parseDate(date)
    const day = asDate.getDate() <= 9 ? `0${asDate.getDate()}` : `${asDate.getDate()}`

    const month = asDate.getMonth() + 1 <= 9 ? `0${asDate.getMonth() + 1}` : `${asDate.getMonth() + 1}`

    return `${asDate.getFullYear()}-${month}-${day}`
  }

  const checkIsDobValid = () => {
    const date = getValues('dob')
    if (date.length < DATE_LENGTH - 2 || date.length > DATE_LENGTH) {
      return 'Invalid date'
    }

    return isDOBValid(date) ? '' : 'You must be at least 18 years old'
  }

  const onClickContinue = handleSubmit(async (formValues: VerifyIdentityState) => {
    const { ssn } = formValues
    analytics.logEvent(Analytics.Events.EMBED_RELINK_VERIFY_IDENTITY_CTA_CLICKED)

    if (!uid || !billerConnection?.biller_account_public_id) {
      logger.error('evaluateOffer', `info: missing customerAccountState data`)
      return
    }

    const isSSNValid = isValidSSN(ssn)
    const dobError = checkIsDobValid()

    if (isValidITIN(ssn)) {
      setError('ssn', { type: 'Social security number', message: 'ITIN is not currently supported' })
    } else if (!isSSNValid) {
      setError('ssn', { type: 'Social security number', message: 'Social security number is invalid' })
    } else {
      clearErrors('ssn')
    }

    if (dobError) {
      setError('dob', { type: 'Invalid date', message: dobError })
    } else {
      clearErrors('dob')
    }

    if (!isSSNValid || dobError) {
      return
    }
    start()
    setLoadingEvaluation(true)
    trackUnderwritingRequested(slcType)

    try {
      await evaluateOffer({
        customerId: uid,
        biller_account_public_id: billerConnection.biller_account_public_id,
        offer_id: offerState.offer.offer_id,
        date_of_birth: getDoB(),
        estimated_income_cent: undefined,
        ssn,
      })
      await refetchEligibility()
      // we check flag enablement in the hook
      await refetchSLC()
    } catch (error: any) {
      setLoadingEvaluation(false)
      logger.error('evaluateOffer - catch', `info: ${error?.message}`)
      redirectToContactSupport()
      stop()
    } finally {
      setLoadingEvaluation(false)
    }
  })

  return loadingEvaluation ? (
    <VerifyingIdentity />
  ) : (
    <VerifyIdentity
      analyticsScreenName={Analytics.Screens.EMBED_RELINK_VERIFY_IDENTITY}
      onClickContinue={onClickContinue}
      ssnPlaceholder={ssnPlaceholder}
      onDateChange={onSetDate}
      onSetSSN={onSetSSN}
      isLoading={loadingEvaluation}
      control={control}
      formErrors={formErrors}
      getValues={getValues}
    />
  )
}
