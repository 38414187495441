import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { VerificationType } from '@genoa/domain'
import { GENERIC_ERROR_MODAL_CONTENT } from '@genoa/screen-content'
import { useQueryError } from '@genoa/state-management'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthState } from '../../../../contexts'
import { useShowErrorMessageModal } from '../../../../hooks'
import { useStartVerificationMutation } from '../../../../modules/flexApi'
import { Config, useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { useOffer } from '../use-offer'
import { DocumentVerification } from './DocumentVerification'

export const DocumentVerificationContainer = () => {
  const [flowVisible, setFlowVisible] = useState(false)
  const offer = useOffer()
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const { showErrorMessage } = useShowErrorMessageModal()
  const navigate = useNavigate()

  const [startVerification, { isLoading, error: startVerificationError }] = useStartVerificationMutation()

  const handleStartVerification = async () => {
    const response = await startVerification({
      customerId: uid!,
      verificationId: offer.offer.onboarding_identity_document_verification?.verification_id!,
      verification_type: VerificationType.IDENTITY_DOCUMENT,
    }).unwrap()
    const docVTransactionToken = response.data.socure_docv_transaction_token
    if (docVTransactionToken) {
      analytics.logEvent(Analytics.Events.DOCV_START_VERIFICATION_SUCCESS)
      launchDocV(docVTransactionToken)
    }
  }

  const launchDocV = (docVTransactionToken: string) => {
    const config = {
      onProgress: (event: any) => {},
      onSuccess: (response: any) => {
        analytics.logEvent(Analytics.Events.DOCV_CONTINUE_EVALUATION_SUCCESS)
        navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_PROCESSING)
      },
      onError: (error: any) => {
        analytics.logEvent(Analytics.Events.DOCV_CONTINUE_EVALUATION_FAILURE)
        navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_RESUBMIT)
      },
      qrCodeNeeded: true,
    }
    setFlowVisible(true)

    setTimeout(() => {
      SocureDocVSDK.launch(Config.SOCURE_SDK_KEY, docVTransactionToken, '#websdk', config)
        .then((response: any) => {
          if (response.result === 'error') {
            setFlowVisible(false)
          }
        })
        .catch((error: any) => {
          setFlowVisible(false)
          analytics.logEvent(Analytics.Events.DOCV_START_VERIFICATION_FAILURE)
        })
    }, 400)
  }

  useQueryError(startVerificationError, {
    onAllErrors() {
      analytics.logEvent(Analytics.Events.DOCV_START_VERIFICATION_FAILURE)
      showErrorMessage(GENERIC_ERROR_MODAL_CONTENT.TITLE, GENERIC_ERROR_MODAL_CONTENT.SUBTITLE)
    },
  })

  return flowVisible ? (
    <SocureDocVSDKContainer>
      <div style={{ width: '100%' }} id="websdk"></div>
    </SocureDocVSDKContainer>
  ) : (
    <DocumentVerification
      analyticsScreenName={Analytics.Screens.DOCUMENT_VERIFICATION}
      onClickContinue={() => handleStartVerification()}
      isLoading={isLoading}
      flowVisible={flowVisible}
    />
  )
}

const SocureDocVSDKContainer = styled(Flex)`
  width: 100%;
  direction: row;
  align-items: center;
  justify-content: center;
`
