import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { StripeCardAddress } from '@genoa/domain'
import { EMBED_CHANGE_CARD as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { StripeCardElement } from '@stripe/stripe-js'
import * as yup from 'yup'

import { BillingAddress } from '../../../../../../modules/flex2/billing'
import { useAnalytics } from '../../../../../../providers'
import { ChevronLeftPurple } from '../../../../../assets'
import { Headline1, InlineButton, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'
import { CardInput } from '../../../../onboarding/payment-method/card/register-card/card-input/CardInput'
import { BillingAddressDetails } from './BillingAddressDetails'
import { UpdateCardBillingAddress } from './UpdateCardBillingAddress'
import { useBillingAddressDetails } from './use-billing-address-details-data'

const billingAddressSchema = yup.object({
  city: yup.string().required('City is required'),
  name: yup.string().required('Name is required'),
  state: yup.string().required('State is required'),
  streetAddress: yup.string().required('Street address is required'),
  zip: yup.number().required('Zip code is required').typeError('Zip code must be a number'),
})

type ChangeCardProps = {
  billingAddressError: string
  cardFormError: string
  isChangeCardVisible: boolean
  isLoading: boolean
  onCardChange: (isCompleted: boolean) => void
  onCardInputReady: (cardElement: StripeCardElement) => void
  onPressAddCTA: (billingAddress: BillingAddress) => void
  processingFeePercentage?: number
  setIsChangeCardVisible: (isChangeCardVisible: boolean) => void
  stripeCardAddress: StripeCardAddress | undefined
}

export const ChangeCard = (props: ChangeCardProps) => {
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const [billingAddress, setBillingAddress] = useState<BillingAddress>()
  const billingAddressDetails = useBillingAddressDetails({ stripeCardAddress: props.stripeCardAddress })
  const billingAddressInfo = billingAddress || billingAddressDetails

  const goBack = () => {
    navigate(-1)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<BillingAddress>({ resolver: yupResolver(billingAddressSchema) })

  useEffect(() => {
    if (billingAddressInfo) {
      reset(billingAddressInfo)
    }
  }, [billingAddressInfo])

  const onPressCTA = handleSubmit((billingAddressSubmission: BillingAddress) => {
    setBillingAddress(billingAddressSubmission)
    analytics.logEvent(Analytics.Events.EMBED_UPDATE_BILL_ADDRESS_CTA_CLICKED)
    props.setIsChangeCardVisible(true)
  })

  const onPressChangeBillingAddress = () => {
    analytics.logEvent(Analytics.Events.EMBED_CHANGE_BILLING_ADDRESS_CLICKED)
    props.setIsChangeCardVisible(false)
  }

  const onPressAddCTA = () => {
    props.onPressAddCTA(billingAddressInfo)
  }

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_CHANGE_CARD} showSettings>
      <UpdateCardBillingAddress
        control={control}
        errors={errors}
        isVisible={!props.isChangeCardVisible}
        onPressCTA={onPressCTA}
      />
      <ChangeCardArea isVisible={props.isChangeCardVisible}>
        <BackButton isDisabled={props.isLoading} onClick={goBack}>
          <Flex alignItems="center">
            <LeftChevron />
            <BackText>{content.BACK}</BackText>
          </Flex>
        </BackButton>

        <Container>
          <Headline1>{content.HEADER}</Headline1>
          <BoxLarge />

          <SmallText>{content.BODY}</SmallText>
          <BoxSmall />

          <Box mt={4}>
            <SmallText>Card details</SmallText>
            <CardInput
              cardFormError={props.cardFormError}
              onCardInputReady={props.onCardInputReady}
              onCardChange={props.onCardChange}
            />
            <NoticeText>{content.NOTICE}</NoticeText>
          </Box>

          <BoxLarge />

          <BillingAddressDetails
            billingAddressInfo={billingAddressInfo}
            billingAddressError={props.billingAddressError}
            isProcessingRegisterCard={props.isLoading}
            onPressAddCTA={onPressAddCTA}
            onPressChangeBillingAddress={onPressChangeBillingAddress}
          />
        </Container>
      </ChangeCardArea>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const ChangeCardArea = styled(Flex)<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
`

const BackButton = styled(InlineButton)`
  color: ${({ theme }) => theme.colors.jewelPurple};
  padding-top: ${({ theme }) => theme.fixedSizes.spacing_200};
`

const LeftChevron = styled(ChevronLeftPurple)`
  height: ${({ theme }) => theme.fixedSizes.md};
`

const BackText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.jewelPurple};
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const NoticeText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.grey};
  display: flex;
  padding-top: ${({ theme }) => theme.fixedSizes.spacing_25};
`

const BoxSmall = styled(Box)`
  min-height: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const BoxLarge = styled(Box)`
  min-height: ${({ theme }) => theme.fixedSizes.spacing_150};
`
